.contactSection {
    background-color: #2a1746;
    color: #fff;
}
button {
    height:56px;
}

#newsletter {
    display: flex;
    padding:55px 5px;
    justify-content: space-between;
    flex-direction: column;
    text-align: center;
}
/*.contactSection[issmalldevice='true'] .newsletter-stack {
    margin-right: 0px;
    align-items: center;
}
.contactSection[issmalldevice='true'] .newsletter-stack button {
    padding:5px;
}*/
#contact-info {
    padding: 55px 95px;
    background-color: #08010a;
}
.contactSection[issmalldevice='true'] #contact-info {
    text-align: center;
    padding: 35px 25px;
}
.MuiInputBase-root { /*material ui sucks*/
    background-color: white;
}
h1 {
    font-size: 1.8em;
    padding-bottom: 0px;
    margin:20px;
}
.contactSection[issmalldevice='true'] h1 {
    font-size: 1.6em;
    text-align: center;
}