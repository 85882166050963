/* Footer */
footer {
  width: 100%;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #2a1746;
  color: #fff;
  font-size: 0.8em;
}

footer .logo {
  height: 60px;
  width: 60px;
  color: #fff;
  font-size: 2em;
  font-weight: 700;
  text-decoration: none;
  text-transform: uppercase;
}

footer .logo img {
  height: 100%;
  width: 100%;
}

footer .social {
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-size: 2em;
  min-width: 210px;
}

footer a {
  margin: 15px 20px;
  color: #fff;
}

footer a:hover {
  color: #7597de;
}

footer[issmalldevice="true"]{
  font-size: 0.8em;
  justify-content: center;
  display: grid;
}

footer[issmalldevice="true"] div {
  margin-top: 0px;
  text-align: center;
}

footer[issmalldevice="true"] p {
  margin-top: 20px;
  font-size: 0.9em;
}

footer[issmalldevice="true"] .statements a {
  font-size: 1.2em;
}

footer[issmalldevice="false"] .statements {
  padding-left:7%;
}

  