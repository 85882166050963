/*
Much of this CSS was provided by Paul Chevrier
https://github.com/iampaulchevrier/
https://codepen.io/iampaulchevrier/pen/eYywbGo 
*/
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800&display=swap");

* {
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
  scrollbar-color: #fff transparent;
}
body {
  background: linear-gradient(#2b1055, #1c0522);
  min-height: 100vh;
  overflow-x: hidden;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #1c0522;
}

::-webkit-scrollbar-thumb {
  background: #fff;
}


