.logoSection {
    position: relative;
    height: 100vh;
    width: 100%;
    padding: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.logoSection[ismobile="true"][isportrait=false] {
    height: 80vh;
}

.logoSection img {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
    pointer-events: none;
}
.logoSection[ismobile="true"][isportrait=false] img {
    height: 120%;
    width: 120%;
    left: -10%;
    top: -10px;
}


.logoSection img#moon {
    mix-blend-mode: screen;
}

.logoSection:before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    height: 300px;
    width: 100%;
    background: linear-gradient(to top, #08010a, transparent);
    z-index: 1000;
}
.logoSection[ismobile="true"][isportrait=false]:before {
    height: 100px; /* less screen height, so make the transition smaller */
}
.scroll-indicator {
	position: absolute;
	top: 80%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.hide {
    display: none;
}

.scroll-indicator span{
	display: block;
	width: 20px;
	height: 20px;
	border-bottom: 2px solid white;
	border-right: 2px solid white;
	transform: rotate(45deg);
	margin: -10px;
	animation: animate 2s infinite;
}

.scroll-indicator span:nth-child(2)
{
	animation-delay: -0.2s;
}

.scroll-indicator span:nth-child(3)
{
	animation-delay: -0.4s;
}

@keyframes animate{
	
	0%{
		opacity: 0;
		transform: rotate(45deg) translate(-20px, -20px);
	}
	50%{
		opacity: 1;
	}
	100%{
		opacity: 0;
		transform: rotate(45deg) translate(20px, 20px);
	}
}