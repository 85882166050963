
@import url("https://fonts.googleapis.com/css2?family=Jolly+Lodger&display=swap");

/* About section */
.aboutSection {
  position: relative;
  display: block;
  padding:10px 0px;
  background-color: #08010a;
  text-align: center;
}
.aboutSection h2 {
  margin: 0.45em;
  color: #fff;
  font-size: 4em;
  padding-bottom: 110px;
}
.aboutSection p {
  margin-top: 1em;
  margin-bottom: 0.5em;
  color: #d0d5dd;
  font-size: 1.4em;
}
.aboutSection p.description {
  padding-bottom:10px;
}
.aboutSection ul {
  margin: 1em 0;
  padding-left: 2.5em;
}
.aboutSection ul li {
  margin: 0.5em 0;
  color: #d0d5dd;
  font-size: 1em;
}
.smart-pumpkin {
  width: 20vh;
  height: 35vh;
}
.recruit-pumpkin {
  width: 35vh;
  height: 35vh;
}

#showcase {
  display:flex;
  justify-content: center;
  padding-bottom:100px;
}
.column-1 {
  flex-shrink: 0; /* shrinks to 0 to apply 70% width*/
  flex-basis: 46%; /* sets initial width to 70% */
}
.column-2 {
  flex-shrink: 0; /* shrinks to 0 to apply 70% width*/
  flex-basis: 47.5%; /* sets initial width to 70% */
}
.box {
  padding: 2px;
  border-radius: 12px;
  border: 2px solid rgb(34, 33, 33);
  margin: 1rem;
  box-shadow: 1px 1px 1px #000;
}

.box:hover {
  cursor: pointer;
  transform: scale(1.05);
  box-shadow: 0px 0px 10px rgb(249, 255, 165), 0 4px 8px rgb(249, 255, 165);
}

.jl-font {
  font-family: "Jolly Lodger", system-ui;
  font-weight: 400;
  font-style: normal;
  font-size: 2em;
}

/* ismobile="true" */
.aboutSection[ismobile="true"] {
    padding: 15px;
}
.aboutSection[ismobile="true"] h2 {
  font-size: 3.25em;
  padding-bottom: 60px;
}
.aboutSection[ismobile="true"] p{
  font-size: 1.3em;
}
/*.aboutSection[ismobile="true"] .smart-pumpkin {
  width: 12vh;
  height: 25vh;
}
.aboutSection[ismobile="true"] .recruit-pumpkin {
  width: 25vh;
  height: 25vh;
}*/

/* is mobile portrait */
.aboutSection[ismobile="true"][isportrait="true"] #showcase {
  flex-direction: column;
}

/* is mobile landscape */
.aboutSection[ismobile="true"][isportrait="false"]  .smart-pumpkin {
  width: 101px;
  height: 168px;
}
.aboutSection[ismobile="true"][isportrait="false"]  .recruit-pumpkin {
  width: 176px;
  height: 168px;
}
.aboutSection[ismobile="true"][isportrait="false"] .column-1 {
  flex-shrink: 0; /* shrinks to 0 to apply 70% width*/
  flex-basis: 46.5%; /* sets initial width to 70% */
}
.aboutSection[ismobile="true"][isportrait="false"] h2 {
  font-size: 3em;
  margin: 0.65em 3em;
  padding-bottom: 60px;
}


/* issmalldevice */
.aboutSection[issmalldevice="true"] .smart-pumpkin {
  width: 17vh;
  height: 30vh;
}
.aboutSection[issmalldevice="true"] .recruit-pumpkin {
  width: 30vh;
  height: 30vh;
}

/* is small device and not mobile */
.aboutSection[ismobile="false"][issmalldevice="true"] #showcase {
  flex-direction: column;
}
.aboutSection[ismobile="false"][issmalldevice="true"] {
  padding:10px 50px;
}

/* ismediumdevice */
.aboutSection[ismediumdevice="true"] .smart-pumpkin {
  width: 20vh;
  height: 35vh;

}
.aboutSection[ismediumdevice="true"]  .recruit-pumpkin {
  width: 35vh;
  height: 35vh;
}
.aboutSection[ismediumdevice="true"] .column-1 {
  flex-shrink: 0; /* shrinks to 0 to apply 70% width*/
  flex-basis: 31%; /* sets initial width to 70% */
}
.aboutSection[ismediumdevice="true"] .column-2 {
  flex-shrink: 0; /* shrinks to 0 to apply 70% width*/
  flex-basis: 30.5%; /* sets initial width to 70% */
}

/*is extra large device*/
.aboutSection[isextralargedevice="true"] .column-1 {
  flex-shrink: 0; /* shrinks to 0 to apply 70% width*/
  flex-basis: 31.4%; /* sets initial width to 70% */
}
.aboutSection[isextralargedevice="true"] .column-2 {
  flex-shrink: 0; /* shrinks to 0 to apply 70% width*/
  flex-basis: 30.5%; /* sets initial width to 70% */
}

/* islargedevice */
.aboutSection[islargedevice="true"] .smart-pumpkin {
  width: 20vh;
  height: 35vh;

}
.aboutSection[islargedevice="true"]  .recruit-pumpkin {
  width: 35vh;
  height: 35vh;
}
.aboutSection[islargedevice="true"] .column-1 {
  flex-shrink: 0; /* shrinks to 0 to apply 70% width*/
  flex-basis: 31%; /* sets initial width to 70% */
}
.aboutSection[islargedevice="true"] .column-2 {
  flex-shrink: 0; /* shrinks to 0 to apply 70% width*/
  flex-basis: 30.5%; /* sets initial width to 70% */
}
